import React from "react";
import Logo from "../Assets/lines_logo.svg";

function Loading() {
  return (
    <section className="loading_section">
      <div className="logo">
        <img src={Logo} className="placeholder_txt" alt="devang" />
      </div>
      <div className="loading_wrapper">
        <div className="bar"></div>
      </div>
    </section>
  );
}

export default Loading;
