import React from "react";
import userImage from "../Assets/userImg.svg";

function AboutMe() {
  return (
    <section className="about_section" id="about">
      <div className="inner_content">
        <div className="section_head poppins w_700 white">About me <span> &nbsp; </span></div>
        <div className="flex">
          <div className="left">
            <div className="about_para f_mono w_500 grey1">
              <p>
                Hello, my name is Devang Saklani and i'm a Front-end developer from India. I'm currently working with <span className="teal">React</span>, <span className="teal">Three js</span> and <span className="teal">Typescript</span>. I love to code and design Websites using <span className="teal">VS Code</span> and  <span className="teal">Figma. </span>
              
              </p>
              <p>
                Lacus, sed auctor malesuada interdum a.
                <span className="teal"> Maecenas cursus </span> est elit
                <span className="teal"> amet phasellus </span>
                semper. Some of the latest technologies i’ve been working with
                recently:
              </p>
            </div>
            <div className="tech_icons">
              <span className="tech_icon" id="ison1">
                Javascript
              </span>
              <span className="tech_icon" id="ison2">
                CSS / SCSS
              </span>
              <span className="tech_icon" id="ison4">
                React.js
              </span>
              <span className="tech_icon" id="ison5">
                Figma
              </span>
              <span className="tech_icon" id="ison3">
                Three.js
              </span>
              <span className="tech_icon" id="ison6">
                Webpack
              </span>
            </div>
          </div>
          <div className="right flex ">
            <div className="user_img">
              <img src={userImage} className="placeholder_txt" alt="Profile photo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
