import React from "react";
import Blog_1 from "./Blogs/Blog1";
import Blog_2 from "./Blogs/Blog2";

function BlogPosts() {
  return (
    <section className="blog_section" id="blogs">
      <div className="inner_content">
        <div className="section_head poppins w_700 white">
          Blog posts <span> &nbsp; </span>
        </div>
        <div className="blogs_container">
          <Blog_1 />
          <Blog_2 />
        </div>
      </div>
    </section>
  );
}

export default BlogPosts;
