import React from "react";

function Contact() {
  return (
    <section className="contact_section" id="contact">
      <div className="inner_content">
        <div className="section_head poppins w_700 white">
          Leave a reply <span> &nbsp; </span>
        </div>
        <div className="details_input_wrapper">
          <form>
            <div className="name">
              <label htmlFor="userName">
                <span className="input_head f_mono w_400 grey1">Name</span>
                <input
                  type="text"
                  id="userName"
                  className="r_mono w_500 white"
                />
              </label>
            </div>
            <div className="email">
              <label htmlFor="userEmail">
                <span className="input_head f_mono w_400 grey1">Email</span>
                <input
                  type="text"
                  id="userEmail"
                  className="r_mono w_500 white"
                />
              </label>
            </div>
            <div className="message">
              <label htmlFor="message">
                <span className="input_head f_mono w_400 grey1">Message</span>
                <textarea
                  name="message"
                  id="message"
                  className="r_mono w_500 white"
                ></textarea>
              </label>
            </div>
            <div className="submit_btn">
              <button type="submit" className="highlighted_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="three_webgl_telephone">
          <canvas></canvas>
        </div>
      </div>
    </section>
  );
}

export default Contact;
