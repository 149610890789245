import React from "react";

function Landing_content() {
  return (
    <header id='head' className='flex'>
      <div className="inner_content">
        <div className="main_head">
          <div className="heading">
            <div className="r_mono w_400 teal sub_head">Hi, my name is</div>
            <h1 className="poppins fullname_head w_600 white">Devang Saklani.</h1>
            <h2 className="poppins w_600">I create new bugs. Everyday.</h2>
          </div>
          <div className="divider"></div>

          <div className="head_para grey1 poppins w_400">
            I’m a Web Developer specializing in building (and occasionally
            designing) creative Web Experiences. Currently i’m learning
            <span className="teal"> Webpack</span>,
            <span className="teal">Typescript </span> and 
            <span className="teal"> React JS</span>.
          </div>
          <button className="highlighted_btn ">Get my Resume</button>
        </div>
        <div className="three_canvas">
          <canvas id='canvas3d'></canvas>
        </div>
      </div>
    </header>
  );
}

export default Landing_content;
