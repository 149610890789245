import React from "react";
import Project1 from "./Projects/Project1";
import Project2 from "./Projects/Project2";

function Projects() {
  return (
    <section className="projects_section" id="projects">
      <div className="inner_content">
        <div className="section_head poppins w_700 white">
          Recent Projects <span> &nbsp; </span>
        </div>
        <div className="projects_container">
          <Project1 />
          <Project2 />
        </div>
      </div>
    </section>
  );
}

export default Projects;
