import React from "react";
import Logo from "../Assets/Logo.svg";

function Navbar() {
  return (
    <>
      <nav id="navbar">
        <div className="inner_content flex">
          <div className="left flex">
            <div className="logo flex">
              <img className="logo_main placeholder_txt" src={Logo} alt="XO" />
            </div>
          </div>
          <div className="right">
            <button className="trigger flex">
              <div className="trigger_icon flex">
                <span className="line" id="line1"></span>
                <span className="line" id="line2"></span>
                <span className="line" id="line3"></span>
              </div>
            </button>
            <div className="hidden_nav flex">
              <ul className="">
                <li>
                  <a href="#about" className="white nav_item r_mono w_500">
                    About
                  </a>
                </li>
                <li>
                  <a href="#" className="white nav_item r_mono w_500">
                    Projects
                  </a>
                </li>
                <li>
                  <a href="#" className="white nav_item r_mono w_500">
                    Blogs
                  </a>
                </li>
                <li>
                  <a href="#" className="white nav_item r_mono w_500">
                    Contact
                  </a>
                </li>
                <li>
                  <button className="white resume_btn highlighted_btn ">
                    Resume
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
