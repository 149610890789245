import React from "react";

import { AiFillGithub } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function FixedItems() {
  return (
    <>
      <div className="social_icons">
        <div className="wrapper flex">
          <a href="#">
            <AiFillGithub className="white" />
          </a>
          <a href="#">
            <FaTwitter className="white" />
          </a>
          <a href="#">
            <FaLinkedin className="white" />
          </a>
          <span className="line"></span>
        </div>
      </div>
      <div className="email">
        <span className="email_address">devangsaklani@gmail.com</span>
        <span className="line"></span>
      </div>
      <div className="copyright f_mono w_400 grey1">
        Designed and Coded by <span className="teal">Devang Saklani</span>
      </div>
    </>
  );
}

export default FixedItems;
