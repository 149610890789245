import React from "react";

function Project_2() {
  return (
    <div className="project_2 flex">
      <div className="details">
        <div className="sub_title teal f_mono w_400">One of my best</div>
        <div className="project_title white f_mono w_700">
          Three.js Portfolio
        </div>

        <div className="project_details grey1 f_mono w_400">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus
            feugiat sit nunc, risus. Lectus in amet euismod proin commodo.
          </p>
        </div>
        <div className="tech_used">
          <span className="tech_icon">React.js</span>
          <span className="tech_icon">Three.js</span>
        </div>
      </div>
      <div className="cover_img">
        <a href="http://#" target="_blank" rel="noopener noreferrer">
          <img
            src="https://via.placeholder.com/400/44FFD1/ffffff?text="
            className="placeholder_txt"
            alt="project cover"
          />
        </a>
      </div>
    </div>
  );
}

export default Project_2;
