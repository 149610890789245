import React, { useRef } from "react";
import Navbar from "./Components/Navbar";
import LandingContent from "./Components/LandingContent";
import AboutMe from "./Components/AboutMe";
import Projects from "./Components/Projects";
import BlogPosts from "./Components/BlogPosts";
import Contact from "./Components/Contact";
import FixedItems from "./Components/FixedItems";
import Loading from "./Components/Loading";

import "./SCSS/styles.css";

function App() {
  return (
    <>
      <Loading />
      <Navbar />
      <LandingContent />
      <AboutMe />
      <Projects />
      <BlogPosts />
      <Contact />
      <FixedItems />
    </>
  );
}

export default App;
