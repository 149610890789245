import React from "react";

import { FaReact } from "react-icons/fa";
import { FaDev } from "react-icons/fa";

function Blog_2() {
  return (
    <div className="Blog_2 flex">
      <div className="details">
        <div className="blog_title white f_mono w_700">
          The most efficient way to learn React.js
        </div>
        <div className="blog_details grey1 f_mono w_400">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos
            aliquid vitae praesentium suscipit cumque tempora dolor ut molestiae
            assumenda delectus, ipsum id ipsa nisi, ducimus totam est
            perferendis non reiciendis?
          </p>
        </div>
        <div className="tech_used">
          <span className="devto">
            <FaDev />
          </span>
          <span className="react_icon">
            <FaReact />
          </span>
        </div>
      </div>
      <div className="cover_img">
        <a href="http://#" target="_blank" rel="noopener noreferrer">
          <img src="#" className="placeholder_txt" alt="blog post cover" />
        </a>
      </div>
    </div>
  );
}

export default Blog_2;
